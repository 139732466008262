import React from "react";
import "./Newstricker.css";


const Newstricker = () => {
    return (
      <div className="news-ticker-container">
        <div className="news-ticker">
          <p>

            Not Too Young For Wealth Training Program {" "}
            <a
              // href="https://www.kingsforms.online/teensvoice-community"
              href="/financialeducation"
              // target="_blank"
              rel="noopener noreferrer"
            >
              Click Here To Get Our Course

            </a>
          </p>
        </div>
      </div>
    );
}

export default Newstricker;
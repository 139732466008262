import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Maincontact.css";

const Maincontact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://formspree.io/f/mleqgbzv",
        formData
      );
      console.log(response.data);
      // Redirect to the thank-you page
      navigate("/thanks");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="maincontact">
      <div className="main__container">
        <div className="form__container">
          <h3>Send Us a Message</h3>
          <form onSubmit={handleSubmit} className="contact__form">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Enter Email..."
              value={formData.email}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              cols="30"
              rows="10"
              placeholder="Write Message.."
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <input type="submit" value="Send" className="send__button" />
          </form>
        </div>
      </div>

      <div className="form__text-box">
        <p>Send us a mail:</p>
        <i
          className="fa-solid fa-envelope"
          style={{ color: "lightblue" }}
        ></i>{" "}
        <a href="mailto:info@teensvoice.org" style={{ color: "black" }}>
          info@teensvoice.org
        </a>{" "}
        <br /> <br />
        <p>Click the link below to call us:</p>
        <a href="tel:+2348066400348" style={{ color: "black" }}>
          Call Now: +234 806 640 0348
        </a>
      </div>
    </div>
  );
};

export default Maincontact;

export const MenuItems = [
    {
        title: "Home",
        url: "/",
        cName: "nav__links",
        icon: "fa-solid fa-house-user"
    },
    {
        title: "About",
        url: "/about",
        cName: "nav__links",
        icon: "fa-solid fa-circle-info",
      },
      // {
      //   title: "Contact",
      //   url: "/contact",
      //   cName: "nav__links",
      // icon: "fa-solid fa-address-book",
      // },
      {
        title: "Programs",
        url: "/programs",
        cName: "nav__links",
        icon: "fa-regular fa-calendar",
      },
      {
        title: "Volunteer",
        url: "/volunteer",
        cName: "nav__links",
        icon: "fa-regular fa-address-book",
      },
      // {
      //   title: "Scholarship",
      //   url: "/scholarship",
      //   cName: "nav__links",
      //   icon: "fa-solid fa-user-graduate",
      // },
      {
        title: "Register",
        url: "/signup",
        cName: "nav__links__mobile",
      },
]
import React from 'react'
import HeroSection from '../component/herosection/HeroSection'
import Credentials from '../component/credential/Credential'
import AboutSection from '../component/aboutsection/AboutSection'
import CourseBreakdown from '../component/coursebreakdown/CourseBreakdown'
import WhatYouGetSection from '../component/whatyouget/WhatYouGet.Section'
import Testimonials from '../component/testimonials/Testimonial'
import FAQSection from '../component/faq/FAQ'
import PricingSection from '../component/pricingcta/PricingCTA'
import Footer from '../component/footersection/FooterSection'

const NotToo = () => {
  return (
    <>
        <HeroSection />
        <Credentials />
        <AboutSection />
        <CourseBreakdown />
        <WhatYouGetSection />
        <Testimonials />
        <FAQSection />
        <PricingSection />
        <Footer />
    </>
    

  )
}

export default NotToo
import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Cyber from '../components/cyber/Cyber'
import Footer from '../components/footer/Footer'

const Crime = () => {
  return (
    <>
        <Navbar />
        <Cyber />
        <Footer />
    </>
    
  )
}

export default Crime
import React from 'react'
import AboutUs from '../components/aboutus/AboutUs'
import Gallery from '../components/gallery/Gallery'
import Navbar from '../components/navbar/Navbar'
import Hero from '../components/hero/Hero'
import HomeImg from "../media/crowd.jpg";
import Newstricker from '../components/newstricker/Newstricker';
import Destination from '../components/destination/Destination';
import Programsbox from "../components/programsbox/Programsbox";
import ContactForm from '../components/contactform/ContactForm'
import Footer from '../components/footer/Footer'



const Home = () => {
  return (
    <div className="mainContainer">
        <Navbar />
        <Hero 
            cName="hero"
            heroImg={HomeImg}
            title="Welcome to Teens Voice"
            text="Let your voice be heard"
            buttonText="Join The Community"
            url="https://www.kingsforms.online/teensvoice-community"
            btnClass="show"
        />
        <Newstricker /> <br />
        <Destination />
        <AboutUs />
        <Gallery />
        <Programsbox /> 
        <ContactForm /> <br />
        <Footer />

    </div>
  )
}

export default Home
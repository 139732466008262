import {Route, Routes, } from 'react-router-dom'
import Home from './routes/Home';
import About from './routes/About';
import Contact from './routes/Contact';
import Volonteer from './routes/Volonteer';
import Vpolicy from './routes/Vpolicy';
import Programs from './routes/Programs';
import Blog from './routes/Blog';
import './App.css';
import Private from './routes/Private';
import Crime from "./routes/Crime";
import Drug from "./routes/Drug";
// import NotTooYoung from './routes/NotTooYoung';
import NotToo from './routes/NotToo';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/volunteer' element={<Volonteer />} />
        <Route path='/programs' element={<Programs />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/volunteerpolicy' element={<Vpolicy />} />
        <Route path="/privacypolicy" element={<Private />} />
        {/* <Route path='/ntyfw' element={<NotTooYoung />} /> */}
        <Route path='/financialeducation' element={<NotToo />} />
        <Route path="/cybercrime" element={<Crime />} />
        <Route path="/drugabuse" element={<Drug />} />
      </Routes>
    </div>
  );
}

export default App;
